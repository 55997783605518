import React, { FC, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import ProductsCards from 'components/ProductsList/ProductsCards';
import PriceSpider from 'common/PriceSpider';
import RelatedProducts from 'components/RelatedProducts';
import SignUpBanner from 'components/SignUpBanner';
import ArticleTeaser from 'components/ArticleTeaser';
import './findYourVeet.scss';
import 'components/ArticleList/ArticleList.scss';

const FindYourVeetPage: FC<FindYourVeetTypes.FindYourVeetProps> = ({
  data: {
    allUmbracoFindYourVeet: { nodes },
    products: { productsCards },
    relatedProductsList: { relatedProductsNodes },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    findYourVeetLabel,
    findYourVeetContent,
    isFindYourVeetTool,
    relatedArticles,
    relatedArticlesTitle,
    relatedProducts,
    resultsTitle,
    disclaimerText,
    relatedArticlesButtonText,
    isLabelsActive,
    startAgainButtonLabel,
  } = nodes[0];

  const [findYourVeetProducts, setFindYourVeetProducts] = useState<
    PageContent.ProductsCardsItemsType[]
  >([]);

  useEffect(() => {
    const searchResults = [] as PageContent.ProductsCardsItemsType[];
    productsCards.forEach((productCard) => {
      const productTags = productCard.findYourVeet?.split(',');
      const currentProduct = window.location.href.split('?').pop();
      productTags?.forEach((tag) => {
        if (tag === currentProduct) {
          searchResults.push(productCard);
        }
      });
    });
    setFindYourVeetProducts(searchResults);
  }, []);

  return (
    <Layout langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {isFindYourVeetTool === '1' ? (
        <>
          <PriceSpider lang={lang} />
          {findYourVeetProducts.length > 0 ? (
            <>
              <div className="results__title color">
                <h2 className="results__title-center">{resultsTitle}</h2>
              </div>
              <ProductsCards
                {...{ productsCards: findYourVeetProducts, learnMoreLabel: findYourVeetLabel }}
              />
              <p className="disclaimer__wrapper">{disclaimerText}</p>
            </>
          ) : (
            <>
              <div className="results__wrapper color">
                <p className="align-center">{findYourVeetContent}</p>
              </div>
              {relatedProductsNodes && relatedProductsNodes.length && relatedProducts ? (
                <RelatedProducts
                  {...{
                    relatedProductsNodes,
                    relatedProductsTitle: relatedProducts.relatedProductsTitle,
                  }}
                />
              ) : null}
              {relatedArticles ? (
                <>
                  {relatedArticlesTitle ? (
                    <h2 className="results__title">{relatedArticlesTitle}</h2>
                  ) : null}
                  <div className="article-list">
                    <div className="article-list__row">
                      {relatedArticles.map((node) => (
                        <ArticleTeaser
                          key={node.title}
                          {...{
                            ...node,
                            isLabelsActive,
                            articleBannerImage: node.articleBannerImage,
                            articleImageAlt: node.articleBannerImageAltText,
                            moreText: relatedArticlesButtonText,
                            moreAriaLabel: relatedArticlesButtonText,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          {startAgainButtonLabel ? (
            <div className="align-center">
              <Link
                className="btn align-self-center"
                to={`/#interact-${process.env.GATSBY_TRYINTERACT_INTEGRATION_ID}`}
              >
                {startAgainButtonLabel}
              </Link>
            </div>
          ) : null}
          <SignUpBanner {...{ lang }} />
        </>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query FindYourVeetQuery(
    $pageId: String
    $lang: String
    $variant: [String]
    $relatedProductsValues: [String]
  ) {
    allUmbracoFindYourVeet(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          lang
          urls {
            ...languageUrls
          }
        }
        seoProps {
          seoMetaDescription
          seoMetaKeywords
          seoMetaTitle
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        findYourVeetContent
        findYourVeetLabel
        isFindYourVeetTool
        relatedArticlesTitle
        relatedArticles {
          articleBannerImage {
            ...umbracoImage
          }
          articleBannerImageAltText
          url
          title
          teaser
        }
        relatedProducts {
          relatedProductsTitle
        }
        resultsTitle
        disclaimerText
        relatedArticlesButtonText
        isLabelsActive
        startAgainButtonLabel
      }
    }
    products: allSalsifyProducts(
      filter: {
        tags: { elemMatch: { name: { in: $variant } } }
        langProps: { lang: { eq: $lang } }
        showProduct: { nin: "0" }
      }
    ) {
      productsCards: nodes {
        productTitle
        productTitleWithSize
        url
        productSize
        productImage
        productEAN
        findYourVeet
        localProductImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
      }
    }
    relatedProductsList: allSalsifyProducts(
      filter: { sku: { in: $relatedProductsValues }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedProductsNodes: nodes {
        localProductImage {
          childImageSharp {
            fluid(quality: 10) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        relatedProducts {
          relatedProductsLearnMore
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
        productTitle
        productDescriptionLong
        productDisclaimers
        productTitleWithSize
        url
      }
    }
  }
`;

export default FindYourVeetPage;
